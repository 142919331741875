export default {
    "pl": {
        "search_filters_component": {
            "filters": "Filtry",
            "filter_by": "Filtruj według",
            "clear": "Jasne",
            "price": "Cena",
            "all_prices": "Wszystkie ceny",
            "free": "Bezpłatny",
            "premium": "Premia",
            "show_all_prices": "Pokaż przedmioty we wszystkich cenach",
            "show_free": "Pokaż tylko bezpłatne przedmioty",
            "show_premium": "Pokaż tylko przedmioty premium",
            "search_in_packs": "Szukaj w paczkach",
            "color": "Kolor",
            "all": "Wszystko",
            "styles": "Style",
            "categories": "Kategorie",
            "close_filters" : "Zamknij filtry",
            "orientation" : "Orientacja",
            "all_orientations" : "Każdy",
            "horizontal" : "Poziomy",
            "vertical" : "Pionowy",
            "show_all_orientations":"Pokaż dowolną orientację",
            "show_horizontal":"Pokaż obrazy poziome",
            "show_vertical":"Pokaż pionowe obrazy"
        },
        "profile_component": {
            "change_profile_picture": "Zmień zdjęcie profilowe",
            "profile_details": "Szczegóły profilu",
            "username": "Nazwa użytkownika",
            "email": "E-mail",
            "pending_validation": "Adres e-mail <span class='bold-font'>{email}</span> oczekuje na weryfikację. Sprawdź swoją skrzynkę e-mail, aby zweryfikować.",
            "name":"Imię (prawdziwe)",
            "notifications":"Powiadomienia",
            "notifications_news":"Otrzymuj wiadomości, promocje i biuletyny od Kiuix",
            "delete_account":"Usuń konto",
            "save_changes":"Zapisz zmiany",
            "change_password_text":"<span class='bold-font'>Zaktualizuj swoje hasło</span> za pomocą przycisku poniżej Zostaniesz przekierowany na nową stronę i będziesz musiał postępować zgodnie z instrukcjami.",
            "change_password_button":"Zmień hasło",
            "user_updated": "Użytkownik został zaktualizowany",
            "delete_sure":"Czy na pewno chcesz usunąć swoje konto użytkownika?",
            "upload_profile_image":"Prześlij zdjęcie profilowe",
            "update_image":"Zaktualizuj obraz"
        },
    }
}
