export default {
    "en": {
        "search_filters_component": {
            "filters": "Filters",
            "filter_by": "Filter by",
            "clear": "Clear",
            "price": "Price",
            "all_prices": "All prices",
            "free": "Free",
            "premium": "Premium",
            "show_all_prices": "Show items of all prices",
            "show_free": "Show free items only",
            "show_premium": "Show premium items only",
            "search_in_packs": "Search in packs",
            "color": "Color",
            "all": "All",
            "styles": "Styles",
            "categories": "Categories",
            "close_filters" : "Close filters",
            "orientation" : "Orientation",
            "all_orientations" : "Any",
            "horizontal" : "Horizontal",
            "vertical" : "Vertical",
            "show_all_orientations":"Show any orientation",
            "show_horizontal":"Show horizontal images",
            "show_vertical":"Show vertical images"
        },
        "profile_component": {
            "change_profile_picture": "Change your profile picture",
            "profile_details": "Profile details",
            "username": "Username",
            "email": "Email",
            "pending_validation": "The email address <span class='bold-font'>{email}</span> is pending validation. Please check your email to validate.",
            "name":"Name (real)",
            "notifications":"Notifications",
            "notifications_news":"Receive news, promotions and newsletters from Kiuix",
            "delete_account":"Delete account",
            "save_changes":"Save changes",
            "change_password_text":"<span class='bold-font'>Update your password</span> through the button below You will be redirected to a new page and must follow the instructions.",
            "change_password_button":"Change password",
            "user_updated": "User has been updated",
            "delete_sure":"Are you sure you want to delete your user account?",
            "upload_profile_image":"Upload profile image",
            "update_image":"Update image"
        },
    }
}
