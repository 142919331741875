<template>
    <div class="container-fluid">
        <div :id="dir+'previewsModal'" class="modal" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header bg-primary-grey-light top-radius">
                        <h4 class="modal-title">Preview images</h4>
                        <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="d-flex flex-wrap align-content-stretch align-items-center justify-content-center">
                            <div v-for="img in JSON.parse(imgs)" :key="img" class="cursor-pointer">
                                <img class="img-fluid  px-2 py-2" :src="img" width="128" @click="setImageField(img)">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="form-row">
            <div class="form-group col-md-3">
                <label>Width</label>
                <input :id="dir+'-pv-width'" type="text" class="form-control border-0 my-radius bg-light-blue" v-model="pv_width">
            </div>
            <div class="form-group col-md-3">
                <label>Height</label>
                <input :id="dir+'-pv-height'" type="text" class="form-control border-0 my-radius bg-light-blue" v-model="pv_height">
            </div>
            <div class="form-group col-md-1">
                <label>Rows</label>
                <input :id="dir+'-pv-rows'" type="text" class="form-control border-0 my-radius bg-light-blue" v-model="pv_rows">
            </div>
            <div class="form-group col-md-1">
                <label>Cols</label>
                <input :id="dir+'-pv-cols'" type="text" class="form-control border-0 my-radius bg-light-blue" v-model="pv_cols">
            </div>
            <div class="form-group col-md-2">
                <label>Margin</label>
                <input :id="dir+'-pv-margin'" type="text" class="form-control border-0 my-radius bg-light-blue" v-model="pv_margin">
            </div>
            <div class="form-group col-md-2">
                <label>Bg</label>
                <input :id="dir+'-bg-color'" value='#ffffff'/>
            </div>
        </div>

        <div class="d-flex align-items-center justify-content-center">
            <div :id="dir+'-bg-preview'" :style="{'width':pv_width+'px','height':pv_height+'px','background-color':'#ffffff'}">
                <table style="border-spacing: 0;">
                <tr v-for="i in parseInt(this.pv_rows)" :key="i">
                    <td v-for="j in parseInt(pv_cols)" :key="j">
                        <div :id="dir+'-icon-preview-'+i+j" :style="{'width':pv_width/pv_cols+'px','height':pv_height/pv_rows+'px'}" @click="openPreviewsModal(dir+'-icon-preview-'+i+j)" class="border-grey cursor-pointer d-flex justify-content-center align-items-center"></div>
                    </td>
                </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
export default {

    props: ['dir','imgs','rows','cols','width','height','margin'],
    data() {
        return {
            pv_width:this.width,
            pv_height:this.height,
            pv_rows:this.rows,
            pv_cols:this.cols,
            pv_margin:this.margin,
            current_preview:""

        }
    },

    methods: {

        openPreviewsModal(preview){
            $('#'+this.dir+'previewsModal').modal("show");
            this.current_preview=preview;
        },
        setImageField(img){
            $('#'+this.current_preview).html('<img class="img-fluid" src="'+img+'">');
            $('#'+this.dir+'previewsModal').modal("hide");
        }

    },
    mounted() {
        let me = this;

            $('#'+this.dir+'-bg-color').spectrum({
            type: "color",
            showInput: true,
            showAlpha: false,
            move: function(color) {
                $('#'+me.dir+'-bg-preview').css('background-color', color.toHexString());
            },
            change: function(color) {
                $('#'+me.dir+'-bg-preview').css('background-color', color.toHexString());
            },
            hide: function(color) {
                $('#'+me.dir+'-bg-preview').css('background-color', color.toHexString());
            }

        });

    }

}
</script>
