<template>
  <div class="">
        <div class="btn-group btn-group-lg w-100 text-center mx-2" role="group" >
            <label class="btn-group btn btn-primary-group d-block" v-for="(project_type, k) in project_types" :key="k" >
                {{project_type.name}}<br>
                <input v-if="type_id==project_type.id" v-on:input="listProjectCategories"  v-on:click="typeChanged" class="radio" :value="project_type.id" type="radio" :name="dir+ '-type'" checked="checked">
                <input v-else v-on:input="listProjectCategories" v-on:click="typeChanged" class="radio" :value="project_type.id" type="radio"  :name="dir+ '-type'">
            </label>
        </div>

        <div class="row mx-1">
            <div class="col-md-9 ">
                <h3 class="mt-3">Categories</h3>
                <div class="row my-panel bg-white">
                    <div class="col-md-6">
                        <div v-if="project_categories1.length" :id="dir+'-filters1'">
                            <span v-for="project_category1 in project_categories1" :key="project_category1.id">
                                <input v-if="filters.includes(project_category1.id)"  type="checkbox" :value="project_category1.id" checked>
                                <input v-else  type="checkbox" :value="project_category1.id" >
                                {{ project_category1.project_filter_name}}<br>
                            </span>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div v-if="project_categories2.length" :id="dir+'-filters2'">
                            <span v-for="project_category2 in project_categories2" :key="project_category2.id">
                                <input v-if="filters.includes(project_category2.id)"  type="checkbox" :value="project_category2.id" checked>
                                <input v-else  type="checkbox" :value="project_category2.id" >
                                {{ project_category2.project_filter_name}}<br>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <h3 class="mt-3">Styles</h3>
                <div v-if="project_styles.length" :id="dir+'-styles'" class="my-panel bg-white">
                    <span v-for="project_style in project_styles" :key="project_style.id">
                        <input v-if="filters.includes(project_style.id)"  type="checkbox" :value="project_style.id" checked>
                        <input v-else  type="checkbox" :value="project_style.id" >
                        {{ project_style.project_filter_name}}<br>
                    </span>
                </div>
                <div v-else class="my-panel bg-white">
                    No styles in this project type
                </div>
            </div>

        </div>

  </div>
</template>
<script>


export default {
    props: ['dir','type_id','draft_id','project_or_draft','old_pack_id','num_colors'],

    data() {
        return {
            project_categories1 : [],
            project_categories2 : [],
            project_styles : [],
            project_types : [],
            rendered : false,
            filters : []
        }
    },

    methods:{
            typeChanged: function (event) {
                saveDraftInReview(this.dir,true);
                /*window.location.href = "/u/review?draft="+this.dir;*/
                location.reload();
            },
        async listProjectCategories(){
            if(this.draft_id > 0){
                var draft_type_id = $('input[name='+this.dir+'-type]:checked').val();

                const res = await axios.get("/project_filters/categories/"+draft_type_id);

                const middleIndex = Math.ceil(res.data.length / 2);

                var i;
                const project_filters = await axios.get("/project_filters/"+this.project_or_draft+"-filters/"+this.draft_id);


                for(i = 0; i < project_filters.data.length; i++){
                        this.filters.push(project_filters.data[i].filter_id);
                }
                this.project_categories1 = res.data.splice(0, middleIndex);
                this.project_categories2 = res.data.splice(-middleIndex);

            }else if(this.old_pack_id >0 ){
                const res = await axios.get("/old_styles/"+this.old_pack_id);

                var i;
                    for(i = 0; i < res.data.length; i++){
                        const style = await axios.get("/from_old_style/"+res.data[i].pacest_id_estilo);
                        if(style.data.length>0){
                            this.filters.push(style.data[0].id);
                        }
                    }

                const res2 = await axios.get("/project_filters/categories/"+this.type_id);
                const middleIndex = Math.ceil(res2.data.length / 2);

                this.project_categories1 = res2.data.splice(0, middleIndex);
                this.project_categories2 = res2.data.splice(-middleIndex);

            }else{}

            this.listProjectStyles();
        },
        async listProjectStyles(){
            if(this.draft_id > 0){
                var draft_type_id = $('input[name='+this.dir+'-type]:checked').val();
                const res = await axios.get("/project_filters/styles/"+draft_type_id);
                this.project_styles = res.data;
            }
            else if(this.old_pack_id >0 ){
                const res = await axios.get("/old_styles/"+this.old_pack_id);

                var i;
                    for(i = 0; i < res.data.length; i++){
                        const style = await axios.get("/from_old_style/"+res.data[i].pacest_id_estilo);
                        if(style.data.lenght>0){
                            this.project_styles.push(style.data[0].id);
                        }
                    }

                const res2 = await axios.get("/project_filters/styles/"+this.type_id);

                this.project_styles = res2.data;
            }else{}
        },
        async listProjectTypes(){
            const res = await axios.get("/admin/project-types/list");
            this.project_types = res.data;
        }
    },
    updated(){
        if(this.rendered === false){
            $( function() {
                    $( ".radio" ).checkboxradio({
                        icon: false
                    });
                });

            this.listProjectCategories();
            this.rendered=true;
        }

    },
    created(){
            if((this.type_id==null || this.type_id=="")){this.type_id=1;}
            this.listProjectTypes();
    }

}
</script>

