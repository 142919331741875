export default {
    "es": {
        "search_filters_component": {
            "filters": "Filtros",
            "filter_by": "Filtrar por",
            "clear": "Limpiar",
            "price": "Precio",
            "all_prices": "Cualquier precio",
            "free": "Gratis",
            "premium": "Premium",
            "show_all_prices": "Todos los precios",
            "show_free": "Mostrar solo recursos gratuitos",
            "show_premium": "Mostrar solo artículos gratuitos",
            "search_in_packs": "Buscar en packs",
            "color": "Color",
            "all": "Todos",
            "styles": "Estilos",
            "categories": "Categorías",
            "close_filters" : "Cerrar filtros",
            "orientation" : "Orientación",
            "all_orientations" : "Todas",
            "horizontal" : "Horizontal",
            "vertical" : "Vertical",
            "show_all_orientations":"Cualquier orientación",
            "show_horizontal":"Mostrar imágenes horizontales",
            "show_vertical":"Mostrar imágenes verticales"
        },
        "profile_component": {
            "change_profile_picture": "Cambia tu foto de perfil",
            "profile_details": "Detalles del perfil",
            "username": "Nombre de usuario",
            "email": "Correo electrónico",
            "pending_validation": "La dirección de correo electrónico <span class='bold-font'>{email}</span> está pendiente de validación. Por favor revise su correo electrónico para validar.",
            "name":"Nombre (real)",
            "notifications":"Notificaciones",
            "notifications_news":"Recibe noticias, promociones y newsletters de Kiuix",
            "delete_account":"Eliminar cuenta",
            "save_changes":"Guardar cambios",
            "change_password_text":"<span class='bold-font'>Actualice su contraseña</span> mediante el siguiente botón. Será redirigido a una nueva página y debe seguir las instrucciones.",
            "change_password_button":"Modificar contraseña",
            "user_updated": "El usuario ha sido modificado",
            "delete_sure":"¿Estás seguro de que quieres eliminar tu cuenta de Kiuix?",
            "upload_profile_image":"Subir imagen de perfil",
            "update_image":"Actualizar imagen"
        },
    }
}
