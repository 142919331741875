export default {
    "id": {
        "search_filters_component": {
            "filters": "Filter",
            "filter_by": "Saring menurut",
            "clear": "Jernih",
            "price": "Harga",
            "all_prices": "Semua harga",
            "free": "Bebas",
            "premium": "Premium",
            "show_all_prices": "Tampilkan item dari semua harga",
            "show_free": "Tampilkan item gratis saja",
            "show_premium": "Tampilkan item premium saja",
            "search_in_packs": "Cari dalam kemasan",
            "color": "Warna",
            "all": "Semua",
            "styles": "Gaya",
            "categories": "Kategori",
            "close_filters" : "Tutup filter",
            "orientation" : "Orientasi",
            "all_orientations" : "Setiap",
            "horizontal" : "Horisontal",
            "vertical" : "Vertikal",
            "show_all_orientations":"Tunjukkan orientasi apapun",
            "show_horizontal":"Tampilkan gambar horizontal",
            "show_vertical":"Tampilkan gambar vertikal"
        },
        "profile_component": {
            "change_profile_picture": "Ganti Foto profilmu",
            "profile_details": "Profil lengkap",
            "username": "Nama belakang",
            "email": "Surel",
            "pending_validation": "Alamat email <span class='bold-font'>{email}</span> sedang menunggu validasi. Silakan periksa email Anda untuk memvalidasi.",
            "name":"Nama (asli)",
            "notifications":"Notifikasi",
            "notifications_news":"Terima berita, promosi, dan buletin dari Kiuix",
            "delete_account":"Hapus akun",
            "save_changes":"Save changes",
            "change_password_text":"<span class='bold-font'>Perbarui kata sandi Anda</span> melalui tombol di bawah Anda akan dialihkan ke halaman baru dan harus mengikuti petunjuknya.",
            "change_password_button":"Ganti kata sandi",
            "user_updated": "Pengguna telah diperbarui",
            "delete_sure":"Apakah Anda yakin ingin menghapus akun pengguna Anda?",
            "upload_profile_image":"Unggah gambar profil",
            "update_image":"Perbarui gambar"
        },
    }
}
