<template>
  <div class="container-fluid">
        <div :id="'autorCreateModal-'+id" class="modal" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header bg-primary-grey-light top-radius">
                    <h4 class="modal-title">Create Autor</h4>
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-group">
                        <label>Autor name</label>
                        <input v-model="autor_name" class="form-control" type="text"  />
                    </div>
                    <div class="form-group">
                        <label>Autor link</label>
                        <input v-model="autor_url" class="form-control" type="text"  />
                    </div>
                </div>
                <div class="modal-footer p-1">
                <button type="button" class="btn btn-primary-light" @click="storeAutor()" data-bs-dismiss="modal">Save autor</button>
                </div>
            </div>
            </div>
        </div>
        <button @click="openUpdateModal()" class="btn btn-sm btn-primary-light"><i class="fa fa-plus text-white"></i></button>
    </div>
</template>
<script>

export default {
    props: ['id'],

    data() {
        return {
            autor_name: "",
            autor_url: "",
            autor_id: 0
        }
    },

    methods:{

        storeAutor(){
              axios.post("/autor", {
                    'autor_name': this.autor_name,
                    'autor_url': this.autor_url
                }  ).then((result) => {

            });

        },
        openUpdateModal(){
            $('#autorCreateModal-'+this.id).modal("show");
        }
    }

}
</script>
