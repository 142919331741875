export default {
    "de": {
        "search_filters_component": {
            "filters": "Filter",
            "filter_by": "Filtern nach",
            "clear": "Klar",
            "price": "Preis",
            "all_prices": "Alle Preise",
            "free": "Frei",
            "premium": "Prämie",
            "show_all_prices": "Artikel aller Preise anzeigen",
            "show_free": "Nur kostenlose Artikel anzeigen",
            "show_premium": "Nur Premium-Artikel anzeigen",
            "search_in_packs": "Suchen Sie in Packungen",
            "color": "Farbe",
            "all": "Alle",
            "styles": "Stile",
            "categories": "Kategorien",
            "close_filters" : "Filter schließen",
            "orientation" : "Orientierung",
            "all_orientations" : "Beliebig",
            "horizontal" : "Horizontal",
            "vertical" : "Vertikal",
            "show_all_orientations":"Beliebige Orientierung anzeigen",
            "show_horizontal":"Horizontale Bilder anzeigen",
            "show_vertical":"Vertikale Bilder anzeigen"
        },
        "profile_component": {
            "change_profile_picture": "Ändere dein Profilbild",
            "profile_details": "Profildetails",
            "username": "Nutzername",
            "email": "Email",
            "pending_validation": "Die E-Mail-Adresse <span class='bold-font'>{email}</span> wartet auf die Validierung. Bitte überprüfen Sie Ihre E-Mails zur Bestätigung.",
            "name":"Name",
            "notifications":"Benachrichtigungen",
            "notifications_news":"Erhalten Sie Neuigkeiten, Aktionen und Newsletter von Kiuix",
            "delete_account":"Konto löschen",
            "save_changes":"Änderungen speichern",
            "change_password_text":"<span class='bold-font'>Aktualisieren Sie Ihr Passwort</span> über die Schaltfläche unten. Sie werden auf eine neue Seite weitergeleitet und müssen den Anweisungen folgen.",
            "change_password_button":"Kennwort ändern",
            "user_updated": "Der Benutzer wurde aktualisiert",
            "delete_sure":"Sind Sie sicher, dass Sie Ihr Benutzerkonto löschen möchten?",
            "upload_profile_image":"Profilbild hochladen",
            "update_image":"Bild aktualisieren"
        },

    }
}
