export default {
    "ko": {
        "search_filters_component": {
            "filters": "필터",
            "filter_by": "필터링 기준",
            "clear": "분명한",
            "price": "가격",
            "all_prices": "모든 가격",
            "free": "무료",
            "premium": "프리미엄",
            "show_all_prices": "모든 가격의 항목 표시",
            "show_free": "무료 항목만 표시",
            "show_premium": "프리미엄 아이템만 표시",
            "search_in_packs": "팩에서 검색",
            "color": "색상",
            "all": "모두",
            "styles": "스타일",
            "categories": "카테고리",
            "close_filters" : "필터 닫기",
            "orientation" : "정위",
            "all_orientations" : "어느",
            "horizontal" : "수평의",
            "vertical" : "수직의",
            "show_all_orientations":"모든 방향 표시",
            "show_horizontal":"가로 이미지 표시",
            "show_vertical":"세로 이미지 표시"
        },
        "profile_component": {
            "change_profile_picture": "프로필 사진 변경",
            "profile_details": "프로필 세부정보",
            "username": "사용자 이름",
            "email": "이메일",
            "pending_validation": "이메일 주소 <span class='bold-font'>{email}</span>가 확인 대기 중입니다. 확인하려면 이메일을 확인하세요.",
            "name":"이름(실명)",
            "notifications":"알림",
            "notifications_news":"Kiuix의 뉴스, 프로모션 및 뉴스레터 수신",
            "delete_account":"계정 삭제",
            "save_changes":"변경 사항을 저장하다",
            "change_password_text":"아래 버튼을 통해 <span class='bold-font'>비밀번호 업데이트</span> 새 페이지로 리디렉션되며 지침을 따라야 합니다.",
            "change_password_button":"비밀번호 변경",
            "user_updated": "사용자가 업데이트되었습니다",
            "delete_sure":"사용자 계정을 삭제하시겠습니까?",
            "upload_profile_image":"프로필 이미지 업로드",
            "update_image":"이미지 업데이트"
        },
    }
}
