export default {
    "fr": {
        "search_filters_component": {
            "filters": "Filtres",
            "filter_by": "Filtrer par",
            "clear": "Clair",
            "price": "Prix",
            "all_prices": "Tous les prix",
            "free": "Gratuit",
            "premium": "Prime",
            "show_all_prices": "Afficher les articles de tous les prix",
            "show_free": "Afficher uniquement les articles gratuits",
            "show_premium": "Afficher uniquement les articles premium",
            "search_in_packs": "Rechercher dans les packs",
            "color": "Couleur",
            "all": "Tout",
            "styles": "Styles",
            "categories": "Catégories",
            "close_filters" : "Fermer les filtres",
            "orientation" : "Orientation",
            "all_orientations" : "N'importe quel",
            "horizontal" : "Horizontal",
            "vertical" : "Vertical",
            "show_all_orientations":"Afficher n'importe quelle orientation",
            "show_horizontal":"Afficher les images horizontales",
            "show_vertical":"Afficher les images verticales"
        },
        "profile_component": {
            "change_profile_picture": "Change ta photo de profil",
            "profile_details": "Détails du profil",
            "username": "Nom d'utilisateur",
            "email": "E-mail",
            "pending_validation": "L'adresse e-mail <span class='bold-font'>{email}</span> est en attente de validation. Veuillez vérifier votre email pour valider.",
            "name":"Nom (réel)",
            "notifications":"Avis",
            "notifications_news":"Recevez les actualités, les promotions et les newsletters de Kiuix",
            "delete_account":"Supprimer le compte",
            "save_changes":"Sauvegarder les modifications",
            "change_password_text":"<span class='bold-font'>Mettre à jour votre mot de passe</span> via le bouton ci-dessous Vous serez redirigé vers une nouvelle page et devrez suivre les instructions.",
            "change_password_button":"Changer le mot de passe",
            "user_updated": "L'utilisateur a été mis à jour",
            "delete_sure":"Êtes-vous sûr de vouloir supprimer votre compte utilisateur?",
            "upload_profile_image":"Télécharger l'image de profil",
            "update_image":"Mettre à jour l'image"
        },
    }
}
