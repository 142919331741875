export default {
    "tr": {
        "search_filters_component": {
            "filters": "Filtreler",
            "filter_by": "Tarafından filtre",
            "clear": "Temizlemek",
            "price": "Fiyat",
            "all_prices": "tüm fiyatlar",
            "free": "Özgür",
            "premium": "Ödül",
            "show_all_prices": "Tüm fiyatların öğelerini göster",
            "show_free": "Yalnızca ücretsiz öğeleri göster",
            "show_premium": "Yalnızca premium öğeleri göster",
            "search_in_packs": "paketlerde ara",
            "color": "Renk",
            "all": "Tüm",
            "styles": "stiller",
            "categories": "Kategoriler",
            "close_filters" : "Filtreleri kapat",
            "orientation" : "Oryantasyon",
            "all_orientations" : "Herhangi",
            "horizontal" : "Yatay",
            "vertical" : "Dikey",
            "show_all_orientations":"Herhangi bir yönü göster",
            "show_horizontal":"Yatay resimleri göster",
            "show_vertical":"Dikey resimleri göster"
        },
        "profile_component": {
            "change_profile_picture": "Profil resmini değiştir",
            "profile_details": "Profil detayları",
            "username": "Kullanıcı adı",
            "email": "E-posta",
            "pending_validation": "<span class='bold-font'>{email}</span> e-posta adresi onaylanmayı bekliyor. Doğrulamak için lütfen e-postanızı kontrol edin.",
            "name":"İsim (gerçek)",
            "notifications":"Bildirimler",
            "notifications_news":"Kiuix'ten haberler, promosyonlar ve haber bültenleri alın",
            "delete_account":"Hesabı sil",
            "save_changes":"Değişiklikleri Kaydet",
            "change_password_text":"Aşağıdaki düğme aracılığıyla <span class='bold-font'>şifrenizi güncelleyin</span> Yeni bir sayfaya yönlendirileceksiniz ve talimatları izlemelisiniz.",
            "change_password_button":"Şifre değiştir",
            "user_updated": "Kullanıcı güncellendi",
            "delete_sure":"Kullanıcı hesabınızı silmek istediğinizden emin misiniz?",
            "upload_profile_image":"Profil resmini yükle",
            "update_image":"Resmi güncelle"
        },
    }
}
