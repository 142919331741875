export default {
    "ru": {
        "search_filters_component": {
            "filters": "Фильтры",
            "filter_by": "Сортировать по",
            "clear": "Прозрачный",
            "price": "Цена",
            "all_prices": "Все цены",
            "free": "Бесплатно",
            "premium": "Премиум",
            "show_all_prices": "Показать товары по всем ценам",
            "show_free": "Показывать только бесплатные товары",
            "show_premium": "Показывать только премиальные предметы",
            "search_in_packs": "Искать в пакетах",
            "color": "Цвет",
            "all": "Все",
            "styles": "Стили",
            "categories": "Категории",
            "close_filters" : "Закрыть фильтры",
            "orientation" : "Ориентация",
            "all_orientations" : "Любой",
            "horizontal" : "Горизонтальный",
            "vertical" : "Вертикальный",
            "show_all_orientations":"Показать любую ориентацию",
            "show_horizontal":"Показать горизонтальные изображения",
            "show_vertical":"Показать вертикальные изображения"
        },
        "profile_component": {
            "change_profile_picture": "Изменить изображение профиля",
            "profile_details": "Детали профиля",
            "username": "Имя пользователя",
            "email": "Электронная почта",
            "pending_validation": "Адрес электронной почты <span class='bold-font'>{email}</span> ожидает проверки. Пожалуйста, проверьте свою электронную почту, чтобы подтвердить.",
            "name":"Имя (настоящее)",
            "notifications":"Уведомления",
            "notifications_news":"Получайте новости, рекламные акции и информационные бюллетени от Kiuix",
            "delete_account":"Удалить аккаунт",
            "save_changes":"Сохранить изменения",
            "change_password_text":"<span class='bold-font'>Обновите пароль</span> с помощью кнопки ниже. Вы будете перенаправлены на новую страницу и должны следовать инструкциям.",
            "change_password_button":"Изменить пароль",
            "user_updated": "Пользователь был обновлен",
            "delete_sure":"Вы уверены, что хотите удалить свою учетную запись?",
            "upload_profile_image":"Загрузить изображение профиля",
            "update_image":"Обновить изображение"
        },
    }
}
