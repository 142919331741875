<template>
        <div v-if="printfilters" id="filters" class="filters d-none d-lg-block">
            <div id="sidebar-filters" class="sidebar-filters open">

                <div class="p-4 mx-auto">
                    <h3 class="grey-color"><i class="icon icon-filter"></i> {{ $t('search_filters_component.filters') }}<i onclick="showFilters()" class="cursor-pointer primary-font-color float-end icon icon-arrow-left-circle"></i></h3>
                    <hr />
                    <label class="text-muted w-100">{{ $t('search_filters_component.filter_by') }} <span v-if="this.filters['category'].values.length > 0 ||
                                                                          this.filters['style'].values.length > 0 ||
                                                                          this.filters['orientation'].values.length > 0 ||
                                                                          this.search_in_packs==true ||
                                                                          this.color_filter.length > 0"
                                                                          @click="clearFilters()"
                                                                          class="float-end btn-link cursor-pointer text-decoration-none">{{ $t('search_filters_component.clear') }}</span></label>

                    <label class="mt-2 text-muted w-100">{{ $t('search_filters_component.price') }}</label>
                    <fieldset>
                        <div class="custom-control custom-radio">
                            <input class="custom-control-input form-check-input cursor-pointer" type="radio" name="price" id="price-all" value="" disabled>
                            <label class="custom-control-label ms-1 form-check-label cursor-pointer" for="price-all" title="" data-bs-toggle="tooltip" data-placement="right" :data-original-title="$t('search_filters_component.show_all_prices')">
                                {{ $t('search_filters_component.all_prices') }}
                            </label>
                        </div>
                        <div class="custom-control custom-radio">
                            <input class="custom-control-input form-check-input cursor-pointer" type="radio" name="price" id="price-free" value="free" checked>
                            <label class="custom-control-label ms-1 form-check-label cursor-pointer" for="price-free" title="" data-bs-toggle="tooltip" data-placement="right" :data-original-title="$t('search_filters_component.show_free')">
                                {{ $t('search_filters_component.free') }}
                            </label>
                        </div>

                        <div class="custom-control custom-radio">
                            <input class="custom-control-input form-check-input cursor-pointer" type="radio" name="price" id="price-premium" value="premium" disabled>
                            <label class="custom-control-label ms-1 form-check-label cursor-pointer" for="price-premium" title="" data-bs-toggle="tooltip" data-placement="right" :data-original-title="$t('search_filters_component.show_premium')">
                                {{ $t('search_filters_component.premium') }}
                            </label>
                        </div>
                    </fieldset>
                    <hr v-if="orientations.length"/>
                    <label class="mt-2 text-muted w-100" v-if="orientations.length">{{ $t('search_filters_component.orientation') }}</label>
                    <fieldset v-if="orientations.length">
                        <div class="custom-control custom-radio" >
                            <input v-model="filters['orientation'].values" class="custom-control-input form-check-input cursor-pointer" type="radio" name="orientation" id="orientation-all" value=""  @change="fetch_data(true,true);" checked>
                            <label class="custom-control-label ms-1 form-check-label cursor-pointer" for="orientation-all" title="" data-bs-toggle="tooltip" data-placement="right" :data-original-title="$t('search_filters_component.show_all_orientations')">
                                {{ $t('search_filters_component.all_orientations') }}
                            </label>
                        </div>
                        <div class="custom-control custom-radio">
                            <input v-model="filters['orientation'].values" class="custom-control-input form-check-input cursor-pointer" type="radio" name="orientation" id="orientation-horizontal" @change="fetch_data(true,true);" value="157">
                            <label class="custom-control-label ms-1 form-check-label cursor-pointer" for="orientation-horizontal" title="" data-bs-toggle="tooltip" data-placement="right" :data-original-title="$t('search_filters_component.show_horizontal')">
                                {{ $t('search_filters_component.horizontal') }}
                            </label>
                        </div>
                        <div class="custom-control custom-radio">
                            <input v-model="filters['orientation'].values" class="custom-control-input form-check-input cursor-pointer" type="radio" name="orientation" id="orientation-vertical" @change="fetch_data(true,true);" value="156" >
                            <label class="custom-control-label ms-1 form-check-label cursor-pointer" for="orientation-vertical" title="" data-bs-toggle="tooltip" data-placement="right" :data-original-title="$t('search_filters_component.show_vertical')">
                                {{ $t('search_filters_component.vertical') }}
                            </label>
                        </div>
                    </fieldset>
                    <hr v-if="packs" />
                    <fieldset v-if="packs">
                        <div class="form-check">
                            <input v-model="search_in_packs" @change="fetch_data(true,true)" class="form-check-input custom-control-input form-check-input cursor-pointer" type="checkbox" name="packs" id="packs" value="">
                            <label class="custom-control-label form-check-label form-check-label cursor-pointer" for="packs" title="" data-bs-toggle="tooltip" data-placement="right" :data-original-title="$t('search_filters_component.search_in_packs')">
                                {{ $t('search_filters_component.search_in_packs') }}
                            </label>
                        </div>
                    </fieldset>
                    <hr v-if="color" />
                    <div v-if="color" class="w-100">
                        <label class="text-muted w-100  text-left">{{ $t('search_filters_component.color') }}</label>
                        <button aria-label="White color" id="color-white" :class="{ 'selected' : this.color_filter === 'white'}" class="btn btn-color border bg-white me-1"  @click="changeColor('white')"></button>
                        <button aria-label="Yellow color" id="color-yellow" :class="{ 'selected' : this.color_filter === 'yellow'}" class="btn btn-color bg-filtercolor-yellow me-1" @click="changeColor('yellow')"></button>
                        <button aria-label="Orange color" id="color-orange" :class="{ 'selected' : this.color_filter === 'orange'}" class="btn btn-color bg-filtercolor-orange me-1" @click="changeColor('orange')"></button>
                        <button aria-label="Red color" id="color-red" :class="{ 'selected' : this.color_filter === 'red'}" class="btn btn-color bg-filtercolor-red me-1" @click="changeColor('red')"></button>
                        <button aria-label="Pink color" id="color-pink" :class="{ 'selected' : this.color_filter === 'pink'}" class="btn btn-color bg-filtercolor-pink me-1" @click="changeColor('pink')"></button>
                        <button aria-label="Purple color" id="color-purple" :class="{ 'selected' : this.color_filter === 'purple'}" class="btn btn-color bg-filtercolor-purple" @click="changeColor('purple')"></button>
                    </div>
                    <div v-if="color" class="w-100 mt-1">
                        <button aria-label="Blue color" id="color-blue" :class="{ 'selected' : this.color_filter === 'blue'}" class="btn btn-color bg-filtercolor-blue me-1" @click="changeColor('blue')"></button>
                        <button aria-label="Cyan color" id="color-cyan" :class="{ 'selected' : this.color_filter === 'cyan'}" class="btn btn-color bg-filtercolor-turquoise me-1" @click="changeColor('cyan')"></button>
                        <button aria-label="Green color" id="color-green" :class="{ 'selected' : this.color_filter === 'green'}" class="btn btn-color bg-filtercolor-green me-1" @click="changeColor('green')"></button>
                        <button aria-label="Brown color" id="color-brown" :class="{ 'selected' : this.color_filter === 'brown'}" class="btn btn-color bg-filtercolor-brown me-1" @click="changeColor('brown')"></button>
                        <button aria-label="Grey color" id="color-grey" :class="{ 'selected' : this.color_filter === 'grey'}" class="btn btn-color bg-filtercolor-grey me-1" @click="changeColor('grey')"></button>
                        <button aria-label="Black color" id="color-black" :class="{ 'selected' : this.color_filter === 'black'}" class="btn btn-color bg-black" @click="changeColor('black')"></button>
                    </div>
                    <hr v-if="styles.length"/>
                    <label class="text-muted w-100" v-if="styles.length">{{ $t('search_filters_component.styles') }}</label>
                    <fieldset v-if="styles.length">
                        <div class="form-check" v-for="style in styles" :key="style.id">
                            <input v-model="filters['style'].values" @change="fetch_data(true,true)" class="custom-control-input form-check-input cursor-pointer" type="checkbox" :name="style.uri" :id="style.uri" :value="style.id">

                            <label class="custom-control-label form-check-label cursor-pointer" :for="style.uri" :title="style.name" data-bs-toggle="tooltip">
                                {{capitalized(style.name)}}
                            </label>
                        </div>
                    </fieldset>
                    <hr v-if="categories.length && this.project_type.filters && !this.project_type.filters.split(',').includes('category_hide')"/>
                    <label class="text-muted w-100" v-if="categories.length && !this.project_type.filters.split(',').includes('category_hide')">{{ $t('search_filters_component.categories') }}</label>
                    <fieldset v-if="categories.length && !this.project_type.filters.split(',').includes('category_hide')">
                        <div class="form-check" v-for="category in categories" :key="category.id">
                            <input v-model="filters['category'].values" @change="fetch_data(true,true);categoryChanged()" class="custom-control-input form-check-input cursor-pointer" type="checkbox" :name="category.uri" :id="category.uri" :value="category.id">
                            <label class="custom-control-label form-check-label cursor-pointer" :for="category.uri" :title="category.name" data-bs-toggle="tooltip">
                                {{capitalized(category.name)}}
                            </label>
                        </div>
                    </fieldset>
                    <button onclick="showFilters()" class="btn btn-outline w-100 mt-3">
                       <i class="icon icon-x text-muted"></i> {{ $t('search_filters_component.close_filters') }}
                    </button>
                </div>
            </div>
        </div>

</template>

<script>

export default {
props: ['base','types','url_categories','project_type_array','printfilters'],

data() {
    return {
        search_in_packs:false,
        mounted:false,
        show_filters:false,
        similar:false,
        type:"",
        type_id:0,
        page:1,
        project_type: [],
        color : false,
        color_filter : "",
        search_string : "",
        search_string_uri : "",
        query_string : "",
        query_string_params: new Map(),
        packs : false,
        styles : [],
        categories : [],
        orientations: [],
        filters: {
            "style":{
                conditions:"check",
                values:[]
            },
            "category":{
                conditions:"check",
                values:[]
            },
            "orientation":{
                conditions:"radio",
                values:""
            }
        }
    }
},

methods:{


    async getProjectType(type){
            var project_type_array = await axios.get(this.base+"/get_project_type_by_plural_with_filters/"+type);
            this.fillFilters(project_type_array.data);
    },
    fillFilters(project_type_array){

        if(project_type_array["project_type"]){
            this.project_type=project_type_array["project_type"];
            if(this.project_type.filters && this.project_type.filters.split(",").includes("color")){
                this.color = true;
            }else this.color = false;

            if(this.project_type.can_be_pack==true){
                this.packs = true;
                if(this.query_string_params.get('search_in_packs')){
                    this.search_in_packs=this.query_string_params.get('search_in_packs');
                }
            }else this.packs = false;
        }
       if(project_type_array["styles"]){
            this.styles=project_type_array["styles"];
        }else this.styles=[];
        if(project_type_array["categories"]){
            this.categories=project_type_array["categories"];
        }else this.categories=[];
        if(project_type_array["orientations"]){
            this.orientations=project_type_array["orientations"];
        }else this.orientations=[];
    },
    changeColor(color){
        $(".btn-color").removeClass("selected");

        if(color == this.color_filter){
            this.color_filter = "";
            $("#color-"+color).removeClass("selected");
        }
        else{
            this.color_filter = color;
            $("#color-"+color).addClass("selected");
        }

        this.fetch_data(true,true);
    },
    capitalized(name) {
        const capitalizedFirst = name[0].toUpperCase();
        const rest = name.slice(1);
        return capitalizedFirst + rest;
    },
    clearFilters(){
        this.filters['category'].values = [];
        this.filters['style'].values = [];
        this.filters['orientation'].values = "";
        this.color_filter = "";
        this.search_in_packs = false;
        this.fetch_data(true,true);
        $(".btn-color").removeClass("selected");
    },
    array_column (input, ColumnKey, IndexKey = null) {
        if (input !== null && (typeof input === 'object' || Array.isArray(input))) {
            const newarray = []
            if (typeof input === 'object') {
            const temparray = []
            for (const key of Object.keys(input)) {
                temparray.push(input[key])
            }
            input = temparray
            }
            if (Array.isArray(input)) {
            for (const key of input.keys()) {
                if (IndexKey && input[key][IndexKey]) {
                if (ColumnKey) {
                    newarray[input[key][IndexKey]] = input[key][ColumnKey]
                } else {
                    newarray[input[key][IndexKey]] = input[key]
                }
                } else {
                if (ColumnKey) {
                    newarray.push(input[key][ColumnKey])
                } else {
                    newarray.push(input[key])
                }
                }
            }
            }
            return Object.assign({}, newarray)
        }
    },


    typeChange: function (){
        if(this.type != 'all-items' && this.show_filters){
            $('#filters').addClass('d-lg-block');
            $('#filters').addClass('open');
            $('#sidebar-filters').addClass('open');

        }else if(this.type == 'all-items'){
             $('#filters').removeClass('open');
             $('#sidebar-filters').removeClass('open');
        }else{
            $('.btn-filters').removeClass('d-lg-none');

        }
        let me = this;



    },
    categoryChanged: function (){
        changeTitle();
    },
    async fetch_data(saveState=true,forceFirstPage=false,force_search_in_packs=false,sendPost=true){
        if(sendPost){
            $('.all-overlay').css("display", "block");
        }
        if(this.mounted){
            var show_filters = this.show_filters;
            if($('#filters').hasClass('open')){
                show_filters=false;
            }
            /*var instance = new ComponentClass({
                propsData: { show_filters: show_filters, type:this.type }
            })
            instance.$mount();
            this.$refs.content.replaceChildren(instance.$el);*/
        }

        //$("#select-type").html($("#select-type").val().charAt(0).toUpperCase() + this.type.slice(1).replace("-"," "));
        //$("#select-type").data("type-name",this.type);
        if(this.type != "all-items" && (!this.project_type || this.project_type.plural_uri!=this.type) ){
            this.filters['category'].values = [];
            this.filters['style'].values = [];
            this.filters['orientation'].values = "";
            this.color_filter = "";
            if(!force_search_in_packs)
                this.search_in_packs = false;

             await this.getProjectType(this.type);

             if(this.url_categories.length>0){
                JSON.parse(this.url_categories).forEach(cat => {
                    this.filters['category'].values.push(cat.id);
                });
            }

        }

        if(forceFirstPage)this.page=1;

        var new_query_string="";
        var category_uri="";
        if(this.color_filter.length>0){
            if(new_query_string.length > 0)new_query_string+="&";
            new_query_string+="color="+this.color_filter;
        }


        if(sendPost){
            category_titles=[];
            if(this.filters['category'].values.length > 0){
                var index=0;
                this.filters['category'].values.forEach(element => {
                    category_uri+=this.array_column(this.categories, 'uri','id')[element];
                    category_titles.push(this.array_column(this.categories, 'name','id')[element])

                    if(index<this.filters['category'].values.length-1){
                        category_uri+="+";
                    }
                    index++;
                });
            }
        }

        if(this.filters['style'].values.length > 0){
            if(new_query_string.length > 0)new_query_string+="&";
            new_query_string+="style=";
            var index=0;
            this.filters['style'].values.forEach(element => {
                new_query_string+=this.array_column(this.styles, 'uri','id')[element];
                if(index<this.filters['style'].values.length-1)new_query_string+=",";
                index++;
            });
        }
        if(this.filters['orientation'].values.length>0){
            if(new_query_string.length > 0)new_query_string+="&";
            new_query_string+="orientation="+this.array_column(this.orientations, 'uri','id')[this.filters['orientation'].values];
        }
        if(this.search_in_packs==true){
            if(new_query_string.length > 0)new_query_string+="&";
            new_query_string+="search_in_packs=true";
        }

        if(this.page > 1){
            if(new_query_string.length > 0)new_query_string+="&";
            new_query_string+="page="+this.page;
            if(this.similar){
                new_query_string="page="+this.page;
            }
        }

        if(saveState){

            const state = { 'search_in_packs': this.search_in_packs,
                            'type':  this.type,
                            'type_id': this.type_id,
                            'page':  this.page,
                            'color':  this.color,
                            'color_filter':  this.color_filter,
                            'search_string': this.search_string ,
                            'search_string_uri': this.search_string_uri ,
                            'query_string': this.query_string ,
                            'query_string_params': JSON.parse(JSON.stringify(this.query_string_params)),
                            'packs': this.packs ,
                            'filters': this.filters,
                            'show_filters': this.show_filters
                        }

            var url = this.base+'/'+this.type;

            if( category_uri.length > 0){
                url = url+"/"+category_uri;
            }
            if(this.search_string_uri)
                url = url+"/"+this.search_string_uri;
            if( new_query_string.length > 0){
                url = url+"?"+new_query_string;
            }
            if(sendPost){
                window.history.pushState(JSON.stringify(state), '', url);
            }else{
                window.history.replaceState(JSON.stringify(state), '',  window.location.href);

            }
        }
        if(sendPost){

            await axios.post(this.base+'/ajax/project_search',{ filter: this.search_string,
                    type:this.type,
                    similar:this.similar,
                    page:this.page,
                    search_in_packs:this.search_in_packs,
                    color_filter:this.color_filter,
                    filters:JSON.stringify(this.filters),
                    base:this.base,
                    categories:category_titles
            }).then((response)=>{
                $("#content").html(response.data);
                this.typeChange();
                updateGrid();
                $('.all-overlay').css("display", "none");

            })
        }
    }
},
created(){

    this.fillFilters(JSON.parse(this.project_type_array));

    this.type = $("#select-type").data("type-name");
    this.type_id = $("#select-type").data("type-id");
    if(this.type!="all-items"){
        this.show_filters=true;
    }
    this.query_string = window.location.search;
    this.search_string = $("#searchbar").attr("data-filter");
    this.similar = $("#searchbar").attr("data-similar");
    this.search_string_uri = $("#searchbar").attr("data-value-uri");
    this.query_string_params = new URLSearchParams(this.query_string);

    if(this.url_categories.length>0){
        JSON.parse(this.url_categories).forEach(cat => {
            this.filters['category'].values.push(cat.id);
        });
    }
    if(this.query_string_params.get("style")){
        this.styles.forEach(style => {
            if(this.query_string_params.get("style").split(',').includes(style.uri)){
                this.filters['style'].values.push(style.id);
            }
        });
    }
    if(this.query_string_params.get("category")){
        this.categories.forEach(category => {
            if(this.query_string_params.get("category").split(',').includes(category.uri)){
                this.filters['category'].values.push(category.id);
            }
        });
    }
    if(this.query_string_params.get("orientation")){
        this.orientations.forEach(orientation => {
            if(this.query_string_params.get("orientation").split(',').includes(orientation.uri)){
                this.filters['orientation'].values=orientation.id;
            }
        });
    }
    if(this.query_string_params.get("color")){
        this.color_filter=this.query_string_params.get("color");
    }

    this.page=this.query_string_params.get('page');


  this.fetch_data(true,false,false,false);


},
mounted: function () {
    window.searchVue=this;
    this.mounted=true;
    this.typeChange();
}
}


</script>

