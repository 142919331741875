export default {
    "zh": {
        "search_filters_component": {
            "filters": "过滤器",
            "filter_by": "过滤",
            "clear": "清除",
            "price": "价格",
            "all_prices": "所有价格",
            "free": "自由的",
            "premium": "优质的",
            "show_all_prices": "显示所有价格的项目",
            "show_free": "仅显示免费项目",
            "show_premium": "仅显示高级项目",
            "search_in_packs": "在包中搜索",
            "color": "颜色",
            "all": "全部",
            "styles": "样式",
            "categories": "类别",
            "close_filters" : "关闭过滤器",
            "orientation" : "方向",
            "all_orientations" : "任何",
            "horizontal" : "水平的",
            "vertical" : "垂直的",
            "show_all_orientations":"显示任意方向",
            "show_horizontal":"显示水平图像",
            "show_vertical":"显示垂直图像"
        },
        "profile_component": {
            "change_profile_picture": "更改您的头像",
            "profile_details": "资料详情",
            "username": "用户名",
            "email": "电子邮件",
            "pending_validation": "电子邮件地址 <span class='bold-font'>{email}</span> 正在等待验证。请检查您的电子邮件以进行验证。",
            "name":"姓名（真实）",
            "notifications":"通知",
            "notifications_news":"从 Kiuix 接收新闻、促销和时事通讯",
            "delete_account":"删除帐户",
            "save_changes":"保存更改",
            "change_password_text":"通过下面的按钮<span class='bold-font'>更新您的密码</span> 您将被重定向到一个新页面并且必须按照说明进行操作。",
            "change_password_button":"更改密码",
            "user_updated": "用户已更新",
            "delete_sure":"您确定要删除您的用户帐户吗？",
            "upload_profile_image":"上传个人资料图片",
            "update_image":"更新图像"
        },
    }
}
