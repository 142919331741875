export default {
    "it": {
        "search_filters_component": {
            "filters": "Filtri",
            "filter_by": "Filtra per",
            "clear": "Chiaro",
            "price": "Prezzo",
            "all_prices": "Tutti i prezzi",
            "free": "Gratuito",
            "premium": "Premio",
            "show_all_prices": "Mostra articoli di tutti i prezzi",
            "show_free": "Mostra solo articoli gratuiti",
            "show_premium": "Mostra solo articoli premium",
            "search_in_packs": "Cerca nei pacchetti",
            "color": "Colore",
            "all": "Tutto",
            "styles": "Stili",
            "categories": "Categorie",
            "close_filters" : "Chiudi i filtri",
            "orientation" : "Orientamento",
            "all_orientations" : "Qualunque",
            "horizontal" : "Orizzontale",
            "vertical" : "Verticale",
            "show_all_orientations":"Mostra qualsiasi orientamento",
            "show_horizontal":"Mostra immagini orizzontali",
            "show_vertical":"Mostra immagini verticali"
        },
        "profile_component": {
            "change_profile_picture": "Cambia la tua immagine di profilo",
            "profile_details": "Dettagli del profilo",
            "username": "Nome utente",
            "email": "E-mail",
            "pending_validation": "L'indirizzo email <span class='bold-font'>{email}</span> è in attesa di convalida. Controlla la tua email per convalidare.",
            "name":"Nome (reale)",
            "notifications":"Notifiche",
            "notifications_news":"Ricevi notizie, promozioni e newsletter da Kiuix",
            "delete_account":"Eliminare l'account",
            "save_changes":"Salvare le modifiche",
            "change_password_text":"<span class='bold-font'>Aggiorna la tua password</span> tramite il pulsante sottostante Verrai reindirizzato a una nuova pagina e dovrai seguire le istruzioni.",
            "change_password_button":"Cambiare la password",
            "user_updated": "L'utente è stato aggiornato",
            "delete_sure":"Sei sicuro di voler eliminare il tuo account utente?",
            "upload_profile_image":"Carica l'immagine del profilo",
            "update_image":"Aggiorna immagine"
        },
    }
}
