export default {
    "ja": {
        "search_filters_component": {
            "filters": "フィルター",
            "filter_by": "フィルタ条件",
            "clear": "クリア",
            "price": "価格",
            "all_prices": "すべての価格",
            "free": "無料",
            "premium": "プレミアム",
            "show_all_prices": "すべての価格のアイテムを表示",
            "show_free": "無料アイテムのみ表示",
            "show_premium": "プレミアム商品のみ表示",
            "search_in_packs": "パックで検索",
            "color": "色",
            "all": "全て",
            "styles": "スタイル",
            "categories": "カテゴリー",
            "close_filters" : "フィルターを閉じる",
            "orientation" : "オリエンテーション",
            "all_orientations" : "どれでも",
            "horizontal" : "水平",
            "vertical" : "垂直",
            "show_all_orientations":"任意の方向を表示",
            "show_horizontal":"横向きの画像を表示する",
            "show_vertical":"縦方向の画像を表示する"
        },
        "profile_component": {
            "change_profile_picture": "プロフィール写真を変更する",
            "profile_details": "プロフィールの詳細",
            "username": "ユーザー名",
            "email": "Eメール",
            "pending_validation": "電子メール アドレス <span class='bold-font'>{email}</span> は検証待ちです。メールを確認して確認してください。",
            "name":"名前（本名）",
            "notifications":"通知",
            "notifications_news":"Kiuix からニュース、プロモーション、ニュースレターを受け取る",
            "delete_account":"アカウントを削除する",
            "save_changes":"変更内容を保存",
            "change_password_text":"下のボタンを使用して<span class='bold-font'>パスワードを更新</span>してください。新しいページにリダイレクトされるので、指示に従う必要があります。",
            "change_password_button":"パスワードを変更する",
            "user_updated": "ユーザーが更新されました",
            "delete_sure":"ユーザーアカウントを削除してもよろしいですか?",
            "upload_profile_image":"プロフィール画像をアップロードする",
            "update_image":"画像を更新"
        },
    }
}
