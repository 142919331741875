export default {
    "pt": {
        "search_filters_component": {
            "filters": "Filtros",
            "filter_by": "Filtrar por",
            "clear": "limpar",
            "price": "Preço",
            "all_prices": "Todos os preços",
            "free": "Livre",
            "premium": "Prêmio",
            "show_all_prices": "Mostrar itens de todos os preços",
            "show_free": "Mostrar apenas itens gratuitos",
            "show_premium": "Mostrar apenas itens premium",
            "search_in_packs": "Pesquisar em pacotes",
            "color": "Cor",
            "all": "Todos",
            "styles": "Estilos",
            "categories": "Categorias",
            "close_filters" : "Fechar filtros",
            "orientation" : "Orientação",
            "all_orientations" : "Qualquer",
            "horizontal" : "Horizontal",
            "vertical" : "Vertical",
            "show_all_orientations":"Mostrar qualquer orientação",
            "show_horizontal":"Mostrar imagens horizontais",
            "show_vertical":"Mostrar imagens verticais"
        },
        "profile_component": {
            "change_profile_picture": "Mude sua foto de perfil",
            "profile_details": "Detalhes de perfil",
            "username": "Nome de usuário",
            "email": "E-mail",
            "pending_validation": "O endereço de e-mail <span class='bold-font'>{email}</span> está com validação pendente. Por favor, verifique seu e-mail para validar.",
            "name":"Nome (verdadeiro)",
            "notifications":"Notificações",
            "notifications_news":"Receba novidades, promoções e newsletters da Kiuix",
            "delete_account":"Deletar conta",
            "save_changes":"Salvar alterações",
            "change_password_text":"<span class='bold-font'>Atualize sua senha</span> através do botão abaixo Você será redirecionado para uma nova página e deverá seguir as instruções.",
            "change_password_button":"Alterar a senha",
            "user_updated": "O usuário foi atualizado",
            "delete_sure":"Tem certeza de que deseja excluir sua conta de usuário?",
            "upload_profile_image":"Carregar imagem de perfil",
            "update_image":"Actualizar imagem"
        },
    }
}
